import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Link  from '../components/delayed-gatsby-link';
import { graphql } from "gatsby"

class Blog extends Component {
  constructor(props) {
    super(props);
    this._itemClicked = this.itemClicked.bind(this);
  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
  }

  getGridItem (node, index, obj) {
    
    if(node.acf){
      return(
        <Link delay={1000} to={"/blog/"+node.slug} onClick={obj._itemClicked}>
          <div className="img-wrap fader">
            <div className="inner">
              <div className="grimage" style={{backgroundImage: "url(" + node.acf.featured_image.source_url + ")", backgroundSize: "cover"}}></div>
            </div>
          </div>
          <div className="innerlay">
            <div className="text-wrap fader">
              <time className="eyebrow">{node.date}</time>
              <h2 className="eyebrow-before">{node.title}</h2>
            </div>
          </div>
        </Link>
      );
    } else {
      return(
        <Link delay={1000} to={"/blog/"+node.slug} onClick={obj._itemClicked}>
          <div className="innerlay">
            <div className="text-wrap fader">
              <time className="eyebrow">{node.date}</time>
              <h2 className="eyebrow-before">{node.title}</h2>
            </div>
          </div>
        </Link>
      );
    }
  }

  render() {
    const data = this.props.data
    const that = this.getGridItem
    const obj = this;

    return (
      <div className="page ColorKey" data-bgcolor="#ffffff">
        <Helmet
          title={'Our Thoughts - illcreative'}
          meta={[
            { name: 'description', content: 'Ideas to grow.'}
          ]}
        />
        <div className="main-content">
          <div className="main-wrap">
            <section className="TopCopy">
              <h2 className="eyebrow fader">Our Thoughts</h2>
              <h1 className="eyebrow-before fader"><span className="fader">Ideas</span> <span className="fader">to</span> <span className="fader"><span className="underline">grow</span>.</span></h1>
            </section>
            <section className="PostGrid no-padding-top no-padding-bottom">
              <ul>
              {data.allWordpressPost.edges.map(({ node, index }) => (
                <li  key={node.slug} className="PostPreview">  
                  {that(node, index, obj)}
                </li>
              ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order:DESC} ) {
      edges {
        node {
          title
          slug
          date(formatString: "MMM DD, YYYY")
          acf {
            featured_image {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`